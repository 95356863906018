@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
* {
  margin: 0;
  
}

body {
  /* overflow: auto; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}
/* body, html {
  height: 100%;
}

html {
  overflow: hidden;
} */
