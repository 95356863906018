@import "bootstrap";


$color_1: var(--imp-text-color);
$color_2: rgb(250, 250, 250);
$color_3: rgb(165, 154, 154);
$color_4: whitesmoke;
$color_5: #cd5ff8;
$color_6: #700c86;
$nav-color_1: rgba(50, 50, 50, 0.5);
$nav-color_2: rgba(98, 98, 98, .95);
$nav-color_3: rgba(50, 50, 50, 0.7);
$font-family_1: sans-serif;
$background-color_1: #0c0513;
$background-color_2: #1b1a2ea9;
$background-color_3: transparent;
$background-color_4: #be50f4;
$background-color_5: #181a27;
$background-color_6: #0a0416;
$background-color_7: #934cce5e;
$background-color_8: #a24dd386;
$background-nav-color_1: rgba(98, 98, 98, 0.5);
$background-nav-color_2: rgba(0, 0, 0, .65);
$background-nav-color_3: rgb(161, 161, 161);
$border-color_1: transparent;
$border-color_2: #934cce5e;
$border-color_3: #a24dd386;
$border-nav-color_1: rgba(84, 59, 163, 0.1);


/* --------- */
/*  Preloader */
/* --------- */
/* --------- */
/*Scrollbar   */
/* --------- */
/* Track */
/* Handle */
/* Handle on hover */
/* --------- */
/* Navbar Section  */
/* --------- */
/* --------- */
/* Home section */
/* --------- */
@keyframes btn-anim1 {
	0% {
		left: -100%;
	}
	50%,100% {
		left: 100%;
	}
}
@keyframes btn-anim2 {
	0% {
		top: -100%;
	}
	50%,100% {
		top: 100%;
	}
}
@keyframes btn-anim3 {
	0% {
		right: -100%;
	}
	50%,100% {
		right: 100%;
	}
}
@keyframes btn-anim4 {
	0% {
		bottom: -100%;
	}
	50%,100% {
		bottom: 100%;
	}
}
/* --------- */
/* Footer */
/* --------- */
/* --------- */
/* Projects */
/* --------- */
/* --------- */
/* About */
/* --------- */
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;

  &-left {
    padding-right: 80px !important;

    @media (max-width: 767px) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  &-right {
    padding-left: 80px !important;

    @media (max-width: 767px) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  & .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  & .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #8a49a8;
    position: relative;

    & .resume-title {
      line-height: 18px;
      font-size: 0.9em;
      background: #5234795d;
      padding: 8px 15px;
      display: inline-block;
      font-weight: 600;
      margin-bottom: 10px;
    }

    ul {
      padding-left: 20px;
      text-align: justify;

      li {
        padding-bottom: 10px;
        list-style: none;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50px;
      left: -9px;
      top: 0;
      background: #fff;
      border: 2px solid #8a49a8;
    }
  }
}

@keyframes likeAnimation {
	0% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
html {
	--section-background-color: linear-gradient( to bottom left, rgba(17, 16, 16, 0.582), rgba(12, 8, 24, 0.904) );
	--image-gradient: linear-gradient( to bottom left, rgba(17, 16, 16, 0.678), rgba(12, 10, 22, 0.863) );
	--imp-text-color: #c770f0;
}
body {
	height: 100%;
}
.purple {
	color: $color_1 !important;
}

// .d-flex.justify-content-between {
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
// }


.btn-primary {
  // width: 150px !important;
  line-height: 1.4em !important;
	background-color: $background-color_7 !important;
  border-color: $border-color_2 !important;
	padding: 0.25rem 1.1rem !important;
	vertical-align: middle !important;
	text-align: center !important;
	&:hover {
		transform: translateY(-2px) !important;
		background-color: $background-color_8 !important;
	}
	&::after {
		display: none !important;
	}
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: $background-color_1;
	background-image: url(./assets/pre.svg);
	background-repeat: no-repeat;
	background-position: center;
}
#preloader-none {
	opacity: 0;
}
#no-scroll {
	overflow: hidden;
	height: 100vh;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-track {
	background: #2d1950;
}
::-webkit-scrollbar-thumb {
	background: rgba(178, 121, 216, 0.959);
	border-radius: 12px;
	&:hover {
		background: rgba(222, 130, 235, 0.911);
		border-radius: 12px;
	}
}
.sticky {
	background-color: $background-color_2 !important;
	transition: all 0.3s ease-out 0s !important;
	box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
	backdrop-filter: blur(15px) !important;
}
.navbar {
	position: fixed !important;
	transition: all 0.3s ease-out 0s !important;
	padding: 0.3rem 2rem !important;
	font-size: 1.2rem !important;
}
.navbar-toggler {
	position: relative !important;
	background-color: $background-color_3 !important;
	border-color: $border-color_1 !important;
	span {
		display: block !important;
		background-color: $background-color_4 !important;
		height: 4px !important;
		width: 27px !important;
		margin-top: 5px !important;
		margin-bottom: 5px !important;
		transform: rotate(0deg) !important;
		left: 0 !important;
		opacity: 1 !important;
		&:nth-child(1) {
			transition: transform 0.35s ease-in-out !important;
			transition: transform 0.35s ease-in-out !important;
		}
		&:nth-child(3) {
			transition: transform 0.35s ease-in-out !important;
			transition: transform 0.35s ease-in-out !important;
		}
	}
	&:focus {
		outline: 0 !important;
	}
	&:active {
		outline: 0 !important;
	}
	&:not(.collapsed) {
		span {
			&:nth-child(1) {
				position: absolute !important;
				left: 12px !important;
				top: 10px !important;
				transform: rotate(135deg) !important;
				opacity: 0.9 !important;
			}
			&:nth-child(2) {
				height: 12px !important;
				visibility: hidden !important;
				background-color: $background-color_3 !important;
			}
			&:nth-child(3) {
				position: absolute !important;
				left: 12px !important;
				top: 10px !important;
				transform: rotate(-135deg) !important;
				opacity: 0.9 !important;
			}
		}
	}
}

.logo {
	height: 1.7em !important;
	width: 2.5em !important;
}
.navbar-nav {
	.nav-link {
		color: $color_3 !important;
		padding-right: 1rem !important;
		padding-left: 1rem !important;
    padding-bottom: 0rem !important;
	}
	.nav-item {
		position: relative;
		margin-left: 20px;
		a {
			font-weight: 400;
			transition: all 0.3s ease-out 0s;
			position: relative;
			z-index: 1;
			&::after {
				content: "";
				position: relative;
				display: block;
				height: 5px;
				width: 0;
				border-radius: 16px;
				background: #c95bf5;
				bottom: 1px;
				left: 0;
				z-index: -1;
				transition: all 0.3s ease-out 0s;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}
		}
	}
}

.home-section {
	position: relative;
	z-index: -1;
	background-position: top center;
	background-repeat: no-repeat;
	padding-bottom: 30px !important;
	padding-top: 30px !important;
}
.home-content {
	padding: 8rem 0 0 !important;
	color: $color_4;
	text-align: left;
}
.heading {
	font-size: 2.4em !important;
	padding-left: 50px !important;
}
.heading-name {
	font-size: 2.5em !important;
	margin-bottom: 0;
}
.main-name {
	color: $color_5;
}
#home-img {
	width: 270px !important;
	height: 270px !important;
	border: 2px solid #cd5ff8;
	box-shadow: 0 0 0 4px #181a27;
	position: absolute !important;
}

.pfp-col {
  display: flex;
  align-items: center;
  justify-content: center;

}

.home-about-section {
	position: relative;
	padding: 10px !important;
}
.home-about-description {
	color: $color_3 !important;
	padding-top: 10px !important;
	padding-bottom: 20px !important;
	text-align: center;
}
.home-about-body {
	font-size: 1.4em !important;
	text-align: left;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.icon-colour {
	color: $color_6 !important;
}

.project-section {
	position: relative !important;
	padding-top: 100px !important;
	padding-bottom: 20px !important;
	// background-image: var(--section-background-color) !important;
}
.project-card {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	height: auto !important;
  width: 26% !important;
}
.project-card-view {
	box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
	color: $color_3 !important;
	background-color: $background-color_3 !important;
	opacity: 0.9 !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
	&:hover {
		transform: scale(1.02) !important;
		overflow: hidden !important;
		box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
	}
}

.proj-button {
  width: 125px !important;
}
.card-img-top {
	width: 100%;
	height: 15vw;
	object-fit: cover;
	padding: 20px !important;
	opacity: 0.8 !important;
	border-radius: 10px !important;
}
.project-heading {
	color: $color_3 !important;
	font-size: 2.3em !important;
	font-weight: 500 !important;
	padding-top: 20px !important;
}

.pro-skill {
  color: $color_3 !important;
	font-size: 2.3em !important;
	font-weight: 500 !important;
	padding-top: 30px !important;
}

.tech-icons {
  font-size: 3em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  
  &:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
  }
}

.tech-icon-images {
	padding: 20px !important;
}

.quote-card-view {
	border: none !important;
	color: $color_3 !important;
	background-color: $background-color_3 !important;
}
.about-activity {
	list-style: none !important;
	text-align: left !important;
	padding-left: 1px !important;
}
.resume-section {
	position: relative !important;
	padding-top: 90px !important;
}

.social-btn {
	padding-top: 7px !important;
}

.social-btn-inner {
	line-height: 1.4em !important;
	background-color: $background-color_8 !important;
  border-color: $border-color_2 !important;
	padding: 0.25rem 0.6rem !important;
	vertical-align: middle !important;
	text-align: center !important;
  margin-left: 10px !important;
	&:hover {
		transform: translateY(-2px) !important;
		background-color: $background-color_8 !important;
    border-color: $border-color_1 !important;
	}
	&::after {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.navbar {
		padding: 0rem !important;
		font-size: 1.4rem !important;
	}
	.navbar-nav {
		.nav-item {
      margin-left: 0px !important;
			a {
				&::after {
					display: none !important;
				}
			}
		}
	} 

  .d-flex {
    padding-left: 16px !important;
  }

  .navbar-toggler.collapsed {
    margin-right: 15px !important;
    padding-top: 13px !important;
  }

  .nav-container{
    padding: 0px !important;
  }

  .navbar-collapse {
    background-color: $background-color_5 !important;

  }

  .heading-name {
    text-align: center !important;
  }

  .home-content {
    padding: 5rem 0 0 !important;
  }

  .home-about-body {
    text-align: center !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
	
	#home-img {
		width: 200px !important;
		height: 200px !important;
		border-radius: 50%;
		border: 2px solid #cd5ff8;
		box-shadow: 0 0 0 4px #181a27;
		position: sticky !important;
	}
  
  .pro-skill {
    padding-top: 30px !important;
    text-align: center;
  }
  
  .project-card {
    width: 100% !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .card-img-top {
    width: 100% !important;
    height: 100% !important;
  }

  .proj-button {
    width: 120px !important;
  }

	.tech-icons {
		margin: 10px !important;
    width: 25% !important;
	}
	.about-img {
		padding-top: 0 !important;
	}
}

@media (min-width: 768px) {
  .col-md-1 {
    width: 5.3333% !important;
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}
